import { SuccesCreateAccountModal as SuccessCreateAccountModal } from 'UI';
import { CourseDtoTypeEnum } from 'api/generated';
import { Loader, SignUpModal } from 'components';
import { SubscribeType } from 'constant';
import { useAsyncAction, useGoogleAnalytics, useNotifications, useQuery } from 'hooks';
import React, { useEffect, useState } from 'react';
import { StorageKeys } from 'services';
import { actions, selectors, useAppSelector } from 'store';
import styled from 'styled-components';

export enum IframeEvents {
  HIDE = 'hide',
  OPEN_SUPPORT = 'open_support',
  SUCCESSFUL_PURCHASE = 'successful_purchase',
}

export enum CreateUserModalsEnum {
  SIGN_UP,
  SUCCESS_CREATE_ACCOUNT,
}

export enum TermEnum {
  MONTHLY = 'monthly',
  YEARLY = 'annual',
  LIFE_TIME = 'lifeTime',
  INSTALLMENT = 'installment',
}

const CreateUser = () => {
  const { errorToast } = useNotifications();
  const [currentModal, setCurrentModal] = useState(CreateUserModalsEnum.SIGN_UP);
  const direction = useAppSelector(selectors.direction.selectDirection);

  const email = useAppSelector(selectors.auth.selectEmail);

  const course = useAppSelector(selectors.courses.selectPublicCourse);
  console.log('🚀 ~ CreateUser ~ course:', course);
  const { getEventSubscriptionPayPressedGA, getEventSubscriptionPaymentModalOpenedGA } = useGoogleAnalytics();
  const courseName = course?.name;

  useEffect(() => {
    if (currentModal === CreateUserModalsEnum.SIGN_UP) {
      getEventSubscriptionPayPressedGA();
    }
  }, [currentModal]);

  const [getCourseByIdPublicAction, isCourseFetching] = useAsyncAction(actions.courses.getCourseByIdPublic);
  const query = useQuery();

  const courseId = query.get(StorageKeys.COURSE_ID);

  const subscribeType = query.get(StorageKeys.SUBSCRIBE_TYPE) as SubscribeType;

  const term = query.get(StorageKeys.TERM_PERIOD) as TermEnum;

  const showCloseButton = query.get(StorageKeys.CLOSE_BUTTON);

  // const courseId = '1e98246e-eece-49cb-8be7-40068af15479';
  // const subscribeType = SubscribeDtoTypeEnum.Gold;
  // const term = TermEnum.MONTHLY;
  // const showCloseButton = false;

  const isCertificate = course?.type === CourseDtoTypeEnum.Certificate;

  const canShowModals = Boolean(courseId && subscribeType && term);

  useEffect(() => {
    if (currentModal === CreateUserModalsEnum.SIGN_UP) {
      getEventSubscriptionPayPressedGA();
      getEventSubscriptionPaymentModalOpenedGA();
    }
  }, [currentModal]);

  const currentPriceMapping = {
    [TermEnum.MONTHLY]: course?.prices?.monthlySubscriptions?.[subscribeType]?.amount || 0,
    [TermEnum.YEARLY]: course?.prices?.annualSubscriptions?.[subscribeType]?.amount || 0,
    [TermEnum.LIFE_TIME]:
      //@ts-ignore
      course?.prices?.purchase?.[subscribeType].amount || 0,
    //@ts-ignore
    [TermEnum.INSTALLMENT]: course?.prices?.installment?.[subscribeType].amount || 0,
  };

  const oldPriceMapping = {
    [TermEnum.MONTHLY]: course?.prices?.monthlySubscriptions?.[subscribeType]?.oldAmount || 0,
    [TermEnum.YEARLY]: course?.prices?.annualSubscriptions?.[subscribeType]?.oldAmount || 0,
    [TermEnum.LIFE_TIME]:
      //@ts-ignore
      course?.prices?.purchase?.[subscribeType].oldAmount || 0,
    //@ts-ignore
    [TermEnum.INSTALLMENT]: course?.prices?.installment?.[subscribeType].oldAmount || 0,
  };

  const defaultCouponMapping = {
    [TermEnum.MONTHLY]: course?.prices?.monthlySubscriptions?.[subscribeType]?.defaultCoupon?.promoCode || '',
    [TermEnum.YEARLY]: course?.prices?.annualSubscriptions?.[subscribeType]?.defaultCoupon?.promoCode || '',
    [TermEnum.LIFE_TIME]:
      //@ts-ignore
      course?.prices?.purchase?.[subscribeType].defaultCoupon?.promoCode || '',
    //@ts-ignore
    [TermEnum.INSTALLMENT]: course?.prices?.installment?.[subscribeType].defaultCoupon?.promoCode || '',
  };

  const defaultFirstCouponInfo = {
    //@ts-ignore
    firstCouponId: course?.prices?.installment?.[subscribeType]?.firstMonthDiscountCouponId || '',
    //@ts-ignore
    firstCouponAmount: course?.prices?.installment?.[subscribeType]?.firstMonthDiscountCoupon?.discountAmount || '',
    //@ts-ignore
    firstCouponType: course?.prices?.installment?.[subscribeType]?.firstMonthDiscountCoupon?.discountType || '',
  };

  const courseOptions = {
    term,
    subscribeType,
    currentPrice: currentPriceMapping[term] || 0,
    oldPrice: oldPriceMapping[term] || 0,
  };

  const getCourse = async (id: string) => {
    try {
      await getCourseByIdPublicAction(id);
    } catch (error: any) {
      errorToast(error?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    if (courseId) {
      getCourse(courseId);
    }
  }, [courseId]);

  const postMessage = (event: IframeEvents, data?: any) => {
    parent.postMessage({ event, data }, direction?.landingUrl || '');
  };

  const closeModals = () => {
    postMessage(IframeEvents.HIDE);
  };

  const closeSignUpModal = () => {
    closeModals();
  };
  const openSuccessModal = () => setCurrentModal(CreateUserModalsEnum.SUCCESS_CREATE_ACCOUNT);
  const closeSuccessModal = () => {
    closeModals();
    setCurrentModal(CreateUserModalsEnum.SIGN_UP);
  };

  const openSupport = () => {
    postMessage(IframeEvents.HIDE);
    postMessage(IframeEvents.OPEN_SUPPORT);
  };

  if (!canShowModals) {
    closeModals();
    return <></>;
  }

  if (isCourseFetching || !course) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <Root>
      <SignUpModal
        email={email}
        courseName={courseName || ''}
        courseId={courseId || ''}
        showCloseButton={showCloseButton ? showCloseButton === 'true' : undefined}
        openSuccessModal={openSuccessModal}
        onRequestClose={closeSignUpModal}
        isOpen={currentModal === CreateUserModalsEnum.SIGN_UP}
        defaultCoupon={defaultCouponMapping[term]}
        currentModal={currentModal}
        defaultFirstCouponInfo={defaultFirstCouponInfo}
        isCertificate={isCertificate}
        {...courseOptions}
      />
      <SuccessCreateAccountModal
        isOpen={currentModal === CreateUserModalsEnum.SUCCESS_CREATE_ACCOUNT}
        email={email}
        courseName={courseName || ''}
        courseId={courseId || ''}
        openSupport={openSupport}
        onClose={closeSuccessModal}
      />
    </Root>
  );
};

export default CreateUser;

const Root = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme: { colors } }) => colors.neutrals[1]};
  opacity: 0.8;
`;
