import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import PriceLabelLifetime from 'UI/PriceLabelLifetime';
import { SubscribeDtoTypeEnum } from 'api/generated';
import { ActivePlan, Plan } from 'components/Modals/ChooseProductModal/components/Products/types';
import { FC } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { getReducedPrice } from 'utils';

interface PriceSectionProps {
  term: TermEnum;
  installmentPriceLifetime: number;
  subscribeType: SubscribeDtoTypeEnum;
  termsCount: number;
  mainColor: string | undefined;
  planType: ActivePlan | undefined;
  isResponsive: boolean;
  goldCount?: number;
  countPlans?: number;
  isMaxHeight?: boolean | undefined;
  onChoosePlan: any;
  isCurrentSubscribe: boolean;
  currentPriceLifetime: number;
  currentPaymentPeriod: string | undefined;
  isGoldSubscribe: boolean;
  isSelect: boolean;
  plan: any;
}

export const PriceSection: FC<PriceSectionProps> = ({
  isResponsive,
  subscribeType,
  mainColor,
  termsCount,
  planType,
  isCurrentSubscribe,
  currentPaymentPeriod,
  onChoosePlan,
  installmentPriceLifetime,
  currentPriceLifetime,
  isSelect,
  plan,
}) => {
  const paymentOption = useAppSelector(selectors.courses.selectPaymentOption);
  const isPaymentPlan = paymentOption === 'paymentPlan';
  const calculateCurrentPrice = (
    amount: number,
    defaultCouponDiscount?: number,
    defaultCouponDiscountType?: string,
  ) => {
    if (defaultCouponDiscount && amount - defaultCouponDiscount >= 1 && defaultCouponDiscountType === 'amount') {
      return getReducedPrice(amount - defaultCouponDiscount);
    }

    if (defaultCouponDiscount && defaultCouponDiscountType === 'percentage') {
      const discountAmount = amount * (defaultCouponDiscount / 100);

      if (currentPriceLifetime - discountAmount >= 1) {
        return getReducedPrice(amount - discountAmount);
      }
    }

    return getReducedPrice(amount);
  };

  return (
    <>
      {plan?.purchasePrice && !isPaymentPlan && (
        <PriceWrapper isResponsive={isResponsive}>
          {isCurrentSubscribe && currentPaymentPeriod === TermEnum.LIFE_TIME && <YourPlan>Your plan</YourPlan>}
          <PriceContainer
            isSelect={isSelect}
            termCount={termsCount}
            mainColor={mainColor}
            activePlan={planType}
            subscribeType={subscribeType}
            term={TermEnum.LIFE_TIME}
            isResponsive={isResponsive}
            onClick={() => onChoosePlan(plan.purchasePrice as Plan, TermEnum.LIFE_TIME, subscribeType)}>
            <StyledPriceLabel
              isResponsive={isResponsive}
              currentPrice={calculateCurrentPrice(
                currentPriceLifetime,
                //@ts-ignore
                plan?.purchasePrice?.defaultCoupon?.discountAmount,
                //@ts-ignore
                plan?.purchasePrice?.defaultCoupon?.discountType,
              )}
              //@ts-ignore
              oldPrice={plan?.purchasePrice?.defaultCoupon?.discountAmount ? currentPriceLifetime : undefined}
              term={TermEnum.LIFE_TIME}
            />
          </PriceContainer>
        </PriceWrapper>
      )}

      {plan?.installmentPrice && isPaymentPlan && (
        <PriceWrapper isResponsive={isResponsive}>
          {isCurrentSubscribe && currentPaymentPeriod === TermEnum.LIFE_TIME && <YourPlan>Your plan</YourPlan>}
          <PriceContainer
            isSelect={isSelect}
            termCount={termsCount}
            mainColor={mainColor}
            activePlan={planType}
            subscribeType={subscribeType}
            term={TermEnum.LIFE_TIME}
            isResponsive={isResponsive}
            onClick={() => onChoosePlan(plan.installmentPrice as Plan, TermEnum.LIFE_TIME, subscribeType)}>
            <StyledPriceLabel
              isResponsive={isResponsive}
              currentPrice={calculateCurrentPrice(
                installmentPriceLifetime,
                //@ts-ignore
                plan?.installmentPrice?.defaultCoupon?.discountAmount,
                //@ts-ignore
                plan?.installmentPrice?.defaultCoupon?.discountType,
              )}
              //@ts-ignore
              oldPrice={plan?.installmentPrice?.defaultCoupon?.discountAmount ? installmentPriceLifetime : undefined}
              term={TermEnum.LIFE_TIME}
            />
          </PriceContainer>
        </PriceWrapper>
      )}
    </>
  );
};

const PriceWrapper = styled.div<{ isResponsive: boolean }>`
  position: relative;
  width: ${({ isResponsive }) => (isResponsive ? '100%' : 'unset')};
`;

const PriceContainer = styled.div<{
  termCount: number;
  activePlan: { planType?: string; term: string } | undefined;
  subscribeType: string;
  mainColor: string | undefined;
  term: string;
  isSelect?: boolean;
  isResponsive: boolean;
}>`
  padding: ${({ subscribeType }) => (subscribeType === 'gold' ? '45px 8px' : '59px 8px')};
  background: ${({ theme: { colors } }) => colors.neutrals[9]};
  border: ${({ subscribeType, activePlan, mainColor, isSelect, term, theme: { colors } }) =>
    (activePlan && subscribeType === activePlan.planType && term === activePlan.term) || isSelect
      ? `2px solid ${mainColor}`
      : `2px solid ${colors.neutrals[9]}`};
  border-radius: 12px;
  align-items: center;
  margin: 12px 0px;
  display: ${({ isResponsive }) => (isResponsive ? 'flex' : 'block')};
  justify-content: ${({ isResponsive }) => (isResponsive ? 'center' : 'unset')};
`;

const StyledPriceLabel = styled(PriceLabelLifetime)<{
  isResponsive: boolean;
}>`
  ${({ theme: { colors, typography } }) => css`
    gap: 8px;

    color: ${colors.neutrals[1]};
    ${typography.headline_semibold_18};
  `}

  display: ${({ isMaxHeight, goldCount }) => (isMaxHeight && goldCount === 1 ? 'flex' : 'flex')};
  flex-direction: ${({ isMaxHeight, goldCount }) => (isMaxHeight && goldCount === 1 ? 'column' : 'unset')};

  @media (max-width: 560px) {
    ${({ theme: { typography } }) => css`
      ${typography.body_large_bold_16}
      font-weight: 600;
      line-height: 14px;
    `}
  }
`;

const YourPlan = styled.p`
  ${({ theme: { typography } }) => typography.footnote_semibold_12}
  background: ${({ theme: { colors } }) => colors.primary[1]};
  padding: 4px 8px;
  border-radius: 12px;
  width: 82px;
  color: ${({ theme: { colors } }) => colors.neutrals[11]};
  position: absolute;
  text-align: center;
  top: -15px;
  right: 5px;
`;
